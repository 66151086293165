.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f7f7; 
  color: #333; 
  padding: 10px 20px;
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.navbar-brand h1 {
  margin: 0;
  font-size: 1.5rem; 
  color: #444; 
}

.navbar-links {
  display: flex;
  align-items: center;
  
}


.navbar-link {
  color: #555; 
  text-decoration: none;
  padding: 0 15px;
  font-size: 1rem; 
  display: flex;
  align-items: center;
  gap: 2px;
  transition: color 0.3s ease;

}

.navbar-link:hover {
  color: #007bff; 
}

.sidebar-toggle {
  font-size: 1.5rem; 
  color: #333; 
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #f0f0f0; 
  color: #333; 
  transition: left 0.3s ease;
  z-index: 1000;
  padding-top: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1); 
}

.sidebar.open {
  left: 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd; 
}

.sidebar-close {
  font-size: 2rem; 
  color: #555; 
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  padding: 10px 20px;
}

.sidebar-menu li a {
  color: #444; 
  text-decoration: none;
  font-size: 1rem; 
  transition: color 0.3s ease;
}

.sidebar-menu li a:hover {
  color: #007bff; 
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); 
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 500;
}

.sidebar-overlay.open {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 768px) {
  .navbar-brand h1 {
      font-size: 1.2rem; 
  }

  .navbar-link {
      font-size: 0.9rem; 
  }

  .sidebar-toggle {
      font-size: 1.2rem; 
  }

  .sidebar-close {
      font-size: 1.5rem; 
  }
}

@media (max-width: 480px) {
  .navbar-brand h1 {
      font-size: 1rem; 
  }

  .navbar-link {
      font-size: 0.8rem; 
  }

  .sidebar-toggle {
      font-size: 1rem; 
  }

  .sidebar-close {
      font-size: 1.2rem; 
  }
}
