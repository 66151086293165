

.shop-container {
    text-align: center;
    padding: 20px;
    
  }
  
  .shop-title {
    font-size: 2.5em;
    margin-bottom: 40px;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
  }
  
  .category-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    transition: transform 0.3s ease;
  }
  
  .category-card:hover {
    transform: translateY(-10px);
  }
  
  .category-image {
    width: 100%;
    max-width: 200px;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .category-name {
    font-size: 1.5em;
    color: #333;
  }
  
  @media (max-width: 768px) {
    .shop-title {
      font-size: 2em;
    }
  
    .category-card {
      max-width: 300px;
    }
  }
  
  @media (max-width: 480px) {
    .shop-title {
      font-size: 1.8em;
    }
  
    .category-name {
      font-size: 1.2em;
    }
  
    .category-card {
      max-width: 250px;
    }
  }
  .shop-page-container {
    padding: 20px;
    text-align: center;
  }
  
  h1 {
    margin-bottom: 30px;
    font-size: 2rem;
    color: #333;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .category-card {
    position: relative;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease;
  }
  
  .category-card:hover {
    transform: scale(1.05);
  }
  
  .category-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .category-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 0 0 8px 8px;
  }
  
  h2 {
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .category-card {
      height: 250px;
    }
  
    .category-image {
      height: 100%;
    }
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 1.5rem;
    }
  
    .category-card {
      height: 200px;
    }
  
    .category-image {
      height: 100%;
    }
  }
  