.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; 
    border: 1px solid #ddd; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    padding: 15px; 
    margin: 15px; 
    width: 250px; 
    transition: transform 0.3s ease;
  }
  
  .product-card:hover {
    transform: translateY(-5px);
  }
  
  .product-image {
    width: 100%; 
    height: auto;
    border-radius: 10px; 
    margin-bottom: 15px; 
  }
  
  .product-name {
    font-size: 1.2rem; 
    margin-bottom: 5px;
    color: #333; 
    text-align: center; 
  }
  
  
  .product-price {
    font-size: 1.1rem;
    color: #555; 
    margin-bottom: 10px;
    text-align: center; 
  }
  

  .cart-btn {
    padding: 10px 20px; 
    background-color: #007bff; 
    color: #fff; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    transition: background-color 0.3s ease; 
  }
  
  .cart-btn:hover {
    background-color: #0056b3; 
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .product-card {
      width: 200px; 
    }
  
    .product-name,
    .product-price {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .product-card {
      width: 50%; 
    }
  
    .product-image {
      height: 150px;
    }
  
    .product-name,
    .product-price {
      font-size: 0.9rem;
    }
  }
  