
.category-page {
    text-align: center;
    padding: 20px;
    /* background-color: #f8f8f8; */
   
  }
  .back-to-categories {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 18px;
    text-decoration: none;
    margin-bottom: 20px;
  }
  
  .back-to-categories:hover {
    color: #e1306c; 
  }
  
  .back-to-categories svg {
    margin-right: 8px;
  }
  .category-page h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    color: #333;
  }
  
  .category-page p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #666; 
  }
  
  .product-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px; 
    max-width: 1200px;
    margin: 0 auto; 
  }
  
  @media screen and (max-width: 768px) {
    .product-grid {
      gap: 15px; 
    }
    .category-page h1 {
        font-size: 1.5rem;
       
      }
      
      .category-page p {
        font-size: 0.8rem;
       
      }
  }
  
  @media screen and (max-width: 480px) {
    .product-grid {
      flex-direction: column;
      justify-content: center;
      gap: 10px; 
    }
    .category-page h1 {
        font-size: 1rem;
       
      }
      
      .category-page p {
        font-size: 0.5rem;
       
      }
  }
  