.hero-section {
    position: relative;
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
  }
  
  .hero-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
  }
  
  .hero-title {
    color: antiquewhite;
    font-size: 36px;
  }
  
  .cta-button {
    
    padding: 10px 20px;
    background-color: #ff6f61;
    color: white;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
  }
  