.homepage-container {
    text-align: center;
    
  }
  
  .responsive-image {
    width: 100%;
    height: auto;
    max-width: 1200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  