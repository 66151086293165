/* Global Styles */
body {
  margin: 0;
  font-family: "Baskervville SC", serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7; 
  color: #333; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
main {
  flex-grow: 1; 
}
