html, body, #root {
  height: 100%; 
  margin: 0;
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.content-wrapper {
  flex-grow: 1; 
}

footer {
  margin-top: auto; 
}
