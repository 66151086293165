
  
  .footer {
    background-color: #333; 
    color: #fff; 
    padding: 20px 0;
    text-align: center;
    width: 100%;
    margin-top: auto; 
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .footer-link {
    color: #fff; 
    text-decoration: none;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .footer-icon {
    margin-right: 8px;
    font-size: 24px;
    color: #e1306c;
  }
  
  .footer-link:hover {
    color: #e1306c;
  }
  
  .footer p {
    margin: 0;
    font-size: 18px;
  }
  
  
  @media (max-width: 600px) {
    .footer-link {
      font-size: 16px;
    }
  
    .footer-icon {
      font-size: 20px;
    }
  }
  