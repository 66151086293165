.cart-page-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
   

  }
  
  .cart-page-title {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 30px;
    font-family: "Baskervville SC", serif;
  }
  
  .cart-items-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .cart-total {
    text-align: center;
    margin-top: 30px;
    font-size: 1.5rem;
    color: #333;
    font-weight: bold;
  }
  
  .cart-empty-message {
    text-align: center;
    font-size: 1.2rem;
    color: #666;
  }
  
  /* Styling for mobile responsiveness */
  @media (max-width: 768px) {
    .cart-page-title {
      font-size: 2rem;
    }
    .cart-total {
      font-size: 1.2rem;
    }
  }
  